let api = 'https://testoms2.szestp.com:8086'   //默认npm run build打包会使用这个地址
if (process.env.NODE_ENV === 'development') { //npm rin build
  // api="http://192.168.88.184:9001"  //开发
  api="https://testoms2.szestp.com:8086"  //开发
} else if (process.env.VUE_APP_CURRENTMODE === 'test') {  //npm run build-test
  api="https://testoms2.szestp.com:8086"  //测试
} else if (process.env.VUE_APP_CURRENTMODE === 'prod') {  //npm run build-prod
  api="https://oms2.szestp.com:8089"  //深圳环境
} else if(process.env.VUE_APP_CURRENTMODE === 'prodPH'){  //npm run build-prodPH
  api="https://ph.szestp.com:8088"  //菲律宾环境
} else if(process.env.VUE_APP_CURRENTMODE === 'prodHK'){  //npm run build-prodHK
  api="https://xg.szestp.com:8099"  //香港环境
} else if(process.env.VUE_APP_CURRENTMODE === 'testHK'){  //npm run build-testHK
  api="https://xg.szestp.com:8081"  //香港测试环境
}else if(process.env.VUE_APP_CURRENTMODE === 'tryOutHK'){  //npm run build-tryOutHK
  api="https://tryoms2.szestp.com:8084"   //香港试用环境
}

export default api
